import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '@/store';

Vue.use(VueRouter);

const routes = [
  {
    path: '/top',
    name: 'top',
    meta: { layout: 'user' },
    component: () =>
      import(/* webpackChunkName: "top-view" */ '../views/TopView.vue'),
  },
  {
    path: '/login',
    name: 'login',
    component: () =>
      import(/* webpackChunkName: "login" */ '../views/Login.vue'),
    meta: { layout: 'auth' },
  },
  {
    path: '/parent/registration',
    name: 'register_customer',
    component: () =>
      import(/* webpackChunkName: "register-customer" */ '../views/parent/InputCustomer.vue'),
    meta: { layout: 'auth', keep_alive: true },
  },
  {
    path: '/parent/register-student',
    name: 'register_student',
    component: () =>
      import(/* webpackChunkName: "register-student" */ '../views/parent/InputStudent.vue'),
    meta: { layout: 'auth', keep_alive: true },
  },
  {
    path: '/parent/setting-password',
    name: 'setting_password',
    component: () =>
      import(/* webpackChunkName: "setting-password" */ '../views/parent/SettingPassword.vue'),
    meta: { layout: 'auth', keep_alive: true },
  },
  {
    path: '/parent/customer-confirm',
    name: 'customer_confirm',
    component: () =>
      import(/* webpackChunkName: "customer-confirm" */ '../views/parent/CustomerConfirm.vue'),
    meta: { layout: 'auth', keep_alive: true },
  },
  {
    path: '/parent/answer-group',
    name: 'answer_group',
    component: () =>
      import(/* webpackChunkName: "answer_group" */ '../views/parent/InputAnswerGroup.vue'),
    meta: { layout: 'auth', keep_alive: true },
  },
  {
    path: '/parent/register/basic',
    name: 'register_basic',
    component: () =>
      import(/* webpackChunkName: "create-parent-basic" */ '../views/parent/RegisterBasic.vue'),
    meta: { layout: 'auth' },
  },
  {
    path: '/top/collections/:collection_id/products',
    name: 'list_products_in_collection_normal_or_addition',
    component: () =>
      import(/* webpackChunkName: "product_list" */ '../views/product/ListProductSellPlusAndNormal.vue'),
    meta: { layout: 'user' },
  },
  {
    path: '/top/collections/:collection_id/products/:product_id',
    name: 'product_detail',
    component: () =>
      import(/* webpackChunkName: "product_detail" */ '../views/product/ProductDetail.vue'),
    meta: { layout: 'user', keep_alive: true },
  },
  {
    path: '/top/collections/:collection_id',
    name: 'product_collection',
    component: () =>
      import(/* webpackChunkName: "product-collection" */ '../views/product/SetProduct.vue'),
    meta: { layout: 'user', keep_alive: true },
  },
  {
    path: '/collection/:collection_id/delivery-checkout',
    name: 'delivery_checkout',
    component: () =>
      import(/* webpackChunkName: "delivery-checkout" */ '../views/order/DeliveryCheckout.vue'),
    meta: { layout: 'user', keep_alive: true },
  },
  {
    path: '/order/confirm',
    name: 'order_confirm',
    component: () =>
      import(/* webpackChunkName: "order_confirm" */ '../views/order/OrderConfirm.vue'),
    meta: { layout: 'user', keep_alive: true },
  },
  {
    path: '/collection/:collection_id/purchase-confirm',
    name: 'purchase_confirm',
    component: () =>
      import(/* webpackChunkName: "checkout" */ '../views/PurchaseConfirm.vue'),
    meta: { layout: 'user', keep_alive: true },
  },
  {
    path: '/cart',
    name: 'cart',
    component: () =>
      import(/* webpackChunkName: "cart" */ '../views/Cart.vue'),
    meta: { layout: 'user', keep_alive: false },
  },
  {
    path: '/terms-of-service',
    name: 'terms_of_service',
    component: () =>
      import(/* webpackChunkName: "terms-of-service" */ '../views/TermsService.vue'),
    meta: { layout: 'user' },
  },
  {
    path: '/my-page',
    name: 'my_page',
    component: () =>
      import(/* webpackChunkName: "my-page" */ '../views/my_page/MyPage.vue'),
    meta: { layout: 'user' },
  },
  {
    path: '/my-page/profile',
    name: 'profile_setting',
    component: () =>
      import(/* webpackChunkName: "setting-profile" */ '../views/my_page/ProfileSetting.vue'),
    meta: { layout: 'user' },
  },
  {
    path: '/faq',
    name: 'faq',
    component: () =>
      import(/* webpackChunkName: "frequently_asked_question" */ '../views/FrequentlyAskedQuestion.vue'),
    meta: { layout: 'user' },
  },
  {
    path: '/my-page/verification-code',
    name: 'verification_code',
    component: () =>
      import(/* webpackChunkName: "verification" */ '../views/my_page/VerificationCode.vue'),
    meta: { layout: 'user' },
  },
  {
    path: '/my-page/size-setting',
    name: 'size_setting',
    component: () =>
      import(/* webpackChunkName: "size_setting" */ '../views/my_page/SizeSetting.vue'),
    meta: { layout: 'user' },
  },
  {
    path: '/my-page/register-student',
    name: 'input_student',
    component: () =>
      import(/* webpackChunkName: "input_student" */ '../views/my_page/InputStudent.vue'),
    meta: { layout: 'user' },
  },
  {
    path: '/reset-password/input-mail',
    name: 'reset_password_input_mail',
    component: () =>
      import(/* webpackChunkName: "reset_password_input_mail" */ '../views/reset_password/InputEmail.vue'),
    meta: { layout: 'auth' },
  },
  {
    path: '/reset-password/input-password',
    name: 'reset_password_input_password',
    component: () =>
      import(/* webpackChunkName: "reset_password_input_password" */ '../views/reset_password/InputPassword.vue'),
    meta: { layout: 'auth' },
  },
  {
    path: '/pages/terms-of-use',
    name: 'terms-of-use',
    component: () =>
      import('../views/pages/Page.vue'),
    meta: { layout: 'main' },
  },
  {
    path: '/pages/return-policy',
    name: 'return-policy',
    component: () =>
      import(/* webpackChunkName: "input-page" */ '../views/pages/Page.vue'),
    meta: { layout: 'main' },
  },
  {
    path: '/pages/privacy-policy',
    name: 'privacy-policy',
    component: () =>
      import(/* webpackChunkName: "input-page" */ '../views/pages/Page.vue'),
    meta: { layout: 'main' },
  },
  {
    path: '/pages/commercial-transaction-law',
    name: 'commercial-transaction-law',
    component: () =>
      import(/* webpackChunkName: "input-page" */ '../views/pages/Page.vue'),
    meta: { layout: 'main' },
  },
  {
    path: '/pages/how-to-cancel-spam',
    name: 'how-to-cancel-spam',
    component: () =>
      import(/* webpackChunkName: "input-page" */ '../views/pages/Page.vue'),
    meta: { layout: 'main' },
  },
  {
    path: '/pages/external-characters',
    name: 'external-characters',
    component: () =>
      import(/* webpackChunkName: "input-page" */ '../views/pages/Page.vue'),
    meta: { layout: 'main' },
  },
  {
    path: '/my-page/order-history',
    name: 'order-history',
    component: () =>
      import(/* webpackChunkName: "order-history" */ '../views/order/OrderHistory.vue'),
    meta: { layout: 'user' },
  },
  {
    path: '/payment',
    name: 'payment-order',
    component: () =>
      import(/* webpackChunkName: "payment-order" */ '../views/PaymentMethod.vue'),
    meta: { layout: 'user', keep_alive: true },
  },
  {
    path: '/payment/:order_id',
    name: 'payment-order-id',
    component: () =>
      import(/* webpackChunkName: "payment-order-id" */ '../views/PaymentMethod.vue'),
    meta: { layout: 'user', keep_alive: true },
  },
  {
    path: '/payment_success',
    name: 'payment-success',
    component: () =>
      import(/* webpackChunkName: "payment-success" */ '../views/SuccessPayment.vue'),
    meta: { layout: 'user' },
  },
  {
    path: '/payment_error',
    name: 'payment-error',
    component: () =>
      import(/* webpackChunkName: "payment-error" */ '../views/ErrorPayment.vue'),
    meta: { layout: 'user' },
  },
  {
    path: '/confirm_student_register_group',
    name: 'confirm-student-register-group',
    component: () =>
      import(/* webpackChunkName: "confirm-student-register-group" */ '../views/my_page/ConfirmStudentRegisterGroup.vue'),
    meta: { layout: 'user' },
  },
  {
    path: '/answer_group',
    name: 'answer-group',
    component: () =>
      import(/* webpackChunkName: "answer-group" */ '../views/my_page/AnswerGroup.vue'),
    meta: { layout: 'user' },
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  const token = store.getters['userStore/token'];
  const routeAuth = [
    'login',
    'register_basic',
    'reset_password_input_mail',
  ];
  const routeFooter = [
    'return-policy',
    'terms-of-use',
    'privacy-policy',
    'commercial-transaction-law',
    'how-to-cancel-spam',
  ];
  const routeNotCheckAuth = [
    'register_customer',
    'register_student',
    'answer_group',
    'setting_password',
    'customer_confirm',
    'reset_password_input_password',
    'external-characters',
  ];
  const root = document.documentElement;
  const delayScrollTime = 50;
  if (routeNotCheckAuth.includes(to.name)) {
    if (to.name === 'register_customer' && token) {
      store.dispatch('userStore/logoutUser');
    }
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, delayScrollTime);
    next();
  } else if (!routeAuth.includes(to.name) && token === '' && !routeFooter.includes(to.name)) {
    return next({ name: 'login' });
  } else if ((routeAuth.includes(to.name) || to.fullPath === '/') && token) {
    return next({ name: 'top' });
  } else if (to.name === 'product_collection') {
    root.classList.add('scroll-behavior-auto');
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, delayScrollTime);
    next();
  } else if (from.name === 'product_collection') {
    // show confirm leave collection set
    const leaveSetWithoutConfirm = {
      purchase_confirm: true,
      login: true,
    };
    const isLeaveConfirmed = store.getters['collectionStore/isLeaveConfirmed'];
    if (leaveSetWithoutConfirm[to.name] || isLeaveConfirmed) {
      store.dispatch('collectionStore/reset');
      if (root.classList.contains('scroll-behavior-auto')) {
        root.classList.remove('scroll-behavior-auto');
      }
      setTimeout(() => {
        window.scrollTo(0, 0);
      }, delayScrollTime);
      next();
    } else {
      store.dispatch('collectionStore/setConfirmRoute', to);
      store.dispatch('collectionStore/setShowLeaveSetConfirm', true);
    }
  } else {
    next();
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, delayScrollTime);
  }
});

router.onError(error => {
  if (/loading chunk \d* failed./i.test(error.message) && navigator.onLine) {
    window.location.reload();
  }
});

export default router;
