<template>
  <user v-if="this.token !== ''"></user>
  <auth v-else></auth>
</template>

<script>
import Auth from './Auth.vue';
import User from './User.vue';
import { mapGetters } from 'vuex';

export default {
  name: 'UserLayout',
  components: {
    Auth,
    User,
  },
  computed: {
    ...mapGetters({
      token: 'userStore/token',
    }),
  },
};
</script>

<style scoped>
</style>
