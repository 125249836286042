export default {
  verification_code: {
    title: '認証コード入力',
    fixed_label_1: '① 登録先を選択してください',
    fixed_label_2: '② 認証コードを入力してください',
    group_code_require: '認証コード',
  },
  input_student: {
    title: '所属情報',
    last_name: '姓',
    first_name: '名',
    kana_last_name: 'セイ',
    kana_first_name: 'メイ',
    date_of_birth: '生年月日',
    gender: '性別',

  },
  confirm: {
    title_verification: '以下の内容で登録してよろしいでしょうか。',
    title_register: '以下の内容で登録してよろしいでしょうか。',
    group: '団体',
    full_name: '氏名',
    full_name_kana: '氏名（カナ）',
    date_of_birth: '生年月日',
    gender: '性別',
    title_answer_group: 'グループの質問に答えます。',
  },
  message: {
    require_gender: '性別の選択がされていません。',
    duplicate_name: '登録済みの生徒名が入力されています。マイページ→認証コード入力の最初の画面に戻り、①登録先で登録済みの生徒名を選択してください。',
    invalid_code: '認証コードが有効ではありません。',
  },
  label: {
    add_new_student: '新しく追加',
  },
};
