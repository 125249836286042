import Vue from 'vue';
import VueI18n from 'vue-i18n';
import enLangCommon from './en/common';
import enValidate from './en/validate';

import jaLangCommon from './ja/common';
import jaLangAuth from './ja/auth';
import jaValidate from './ja/validate';
import jaRegister from './ja/register';
import jaHeader from './ja/header';
import jaTop from './ja/top';
import jaResetPassword from './ja/resetPassword';
import jaProduct from './ja/product';
import jaPurchase from './ja/purchase';
import jaProfile from './ja/profile';
import jaStudent from './ja/student';
import jaCart from './ja/cart';
import jaDelivery from './ja/delivery';
import jaOrder from './ja/order';
import jaPayment from './ja/payment';
import jaPage from './ja/page';

import jaMySize from './ja/mySize';
Vue.use(VueI18n);

const langs = {
  en: {
    common: enLangCommon,
    validate: enValidate,
  },
  ja: {
    common: jaLangCommon,
    auth: jaLangAuth,
    validate: jaValidate,
    header: jaHeader,
    top: jaTop,
    register: jaRegister,
    resetPassword: jaResetPassword,
    product: jaProduct,
    purchase: jaPurchase,
    profile: jaProfile,
    mySize: jaMySize,
    student: jaStudent,
    cart: jaCart,
    delivery: jaDelivery,
    order: jaOrder,
    payment: jaPayment,
    page: jaPage,
  },
};

export default new VueI18n({
  locale: process.env.VUE_APP_I18N_LOCALE || 'ja',
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'ja',
  messages: langs,
  silentFallbackWarn: true,
});
