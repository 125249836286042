const getDefaultState = () => {
  return {
    is_open: false,
  };
};

const state = getDefaultState();
const getters = {
  is_open () {
    return state.is_open;
  },
};

const mutations = {
  TOGGLE (state, status) {
    state.is_open = status;
  },
};
const actions = {
  onToggle ({ commit }, status) {
    commit('TOGGLE', status);
  },
};
export default {
  state,
  mutations,
  actions,
  getters,
};
