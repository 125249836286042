const constant = {
  max_file_size: 5242880, // 5MB
  image_types: ['jpg', 'jpeg', 'png'],
  pagination_total_visible: 7,
  pagination_options: [20, 50, 100],
  prefectures: {
    hokkaido: '北海道',

    aomori: '青森県',
    iwate: '岩手県',
    miyagi: '宮城県',
    akita: '秋田県',
    yamagata: '山形県',
    fukushima: '福島県',

    tokyo: '東京都',
    ibaraki: '茨城県',
    kanagawa: '神奈川県',
    tochigi: '栃木県',
    chiba: '千葉県',
    gunma: '群馬県',
    yamanashi: '山梨県',
    saitama: '埼玉県',

    niigata: '新潟県',
    nagano: '長野県',
    toyama: '富山県',
    ishikawa: '石川県',
    fukui: '福井県',

    shizuoka: '静岡県',
    aichi: '愛知県',
    gifu: '岐阜県',
    mie: '三重県',

    shiga: '滋賀県',
    kyoto: '京都府',
    hyogo: '兵庫県',
    osaka: '大阪府',
    nara: '奈良県',
    wakayama: '和歌山県',

    tottori: '鳥取県',
    shimane: '島根県',
    okayama: '岡山県',
    hiroshima: '広島県',
    yamaguchi: '山口県',

    kagawa: '香川県',
    ehime: '愛媛県',
    tokushima: '徳島県',
    kochi: '高知県',

    fukuoka: '福岡県',
    saga: '佐賀県',
    nagasaki: '長崎県',
    kumamoto: '熊本県',
    oita: '大分県',
    miyazaki: '宮崎県',
    kagoshima: '鹿児島県',

    okinawa: '沖縄県',
  },
  genders: [
    {
      text: '-',
      value: '',
    },
    {
      text: '男',
      value: 1,
    },
    {
      text: '女',
      value: 2,
    },
  ],
  gender_value: {
    male: 1,
    female: 2,
  },
  school_group_question_rules: {
    no_rule: 'no-rule',
    only_half_width: 'only-half-width',
    only_half_width_number: 'only-half-width-number',
  },
  type_question: {
    select: 'select',
    textarea: 'textarea',
    checkbox: 'checkbox',
    radio: 'radio',
  },
  key_check_router: 'check',
  type_date: 'date',
  type_month: 'month',
  add_new_student: '新しく追加',
  type_collection: {
    set_bulk: 1,
    set_addition: 2,
    set_normally: 3,
  },
  type_no_answer_group: 0,
  saddle_sign: '￥',
  months: [
    {
      value: null,
      label: '月',
    },
    {
      value: '01',
      label: '01',
    },
    {
      value: '02',
      label: '02',
    },
    {
      value: '03',
      label: '03',
    },
    {
      value: '04',
      label: '04',
    },
    {
      value: '05',
      label: '05',
    },
    {
      value: '06',
      label: '06',
    },
    {
      value: '07',
      label: '07',
    },
    {
      value: '08',
      label: '08',
    },
    {
      value: '09',
      label: '09',
    },
    {
      value: '10',
      label: '10',
    },
    {
      value: '11',
      label: '11',
    },
    {
      value: '12',
      label: '12',
    },
  ],
  payment: {
    pay_type: 'Card',
    method: '1',
    credit_card: '1',
    bank_transfer: '2',
    type_visa: '1',
    master_card: '2',
    jbc: '3',
    american_express: '4',
    diners_club: '5',
    type_card: 0,
    gmo_number: 1,
  },
  payment_status: {
    not_yet: 1,
    pending: 2,
    success: 3,
    cancel: 4,
  },
  label_year: '年',
};

export default constant;
