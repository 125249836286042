export default {
  label: {
    total: '合計金額',
    product_size: 'サイズ表',
    product_description: 'アイテム説明',
    dealer: '取り扱い業者',
    dealer_name: '業者名:',
    dealer_phone: '電話番号:',
    dealer_email: 'メールアドレス:',
    quantity: '数量',
    recommend_size: '推奨サイズ：{size}',
    tax_include: '(税込)',
    size: 'サイズ',
  },
  button: {
    first: '最初に戻る',
    back: '一つ戻る',
    next: '次へ',
    how_to_buy: '買い方',
    recommend: 'マイサイズ機能とは？',
    buy: '注文する',
    add_to_cart: 'カートに入れる',
  },
  dialog: {
    require_product: {
      message: 'これは必須注文商品です。',
    },
    sellect_option: {
      message: '未選択のオプションがあります。',
    },
    recommend: {
      title: 'マイサイズ機能とは？',
      message: '<p class="mb-0">お客様にご入力頂いた身長、体重等の身体データに基づいて、【適正サイズ】をご案内する機能です。</p>' +
               '<p>現在の【適切サイズ】が表示されますので、そのサイズからお子様の成長を鑑みてご注文頂くサイズをお選びください。</p>' +
               '<p>身体データのご入力はメニュー>マイページ>マイサイズ設定より行うことができます。</p>',
    },
    guide: {
      title: '買い方',
      message_1: '1.学校からの案内',
      message_2: '2.注文の仕方',
    },
    without_purchase_confirm: {
      message: 'この商品を注文せずに次へ進んでよろしいですか？',
      button: {
        confirm: 'はい',
        cancel: '戻る',
      },
    },
    change_student_alert: {
      message: '商品がカートに残っている状態で、生徒の切り替えはできません。商品の注文を済ませるか、カートを空にしてください。',
    },
    confirm_leave_set: {
      message: '買い物の途中ですが、このページを離れてもよろしいですか？<br>※このページを離れるとこれまでの手続きが無効になります',
    },
    note_for_cate_addition: {
      title: '買い方',
      message: 'こちらは、買い忘れ商品や追加でサイズ違いの商品を購入するカテゴリーです。事前の注文の支払方法やサイズ変更をご希望の場合は、お手数ですがカスタマーサポート（0570-031-585）へご連絡をお願いします。',
    },
  },
  message: {
    add_cart_success: '商品がカートに入りました。',
    out_of_stock: '在庫なし。',
    required: '※必須注文商品です。',
  },
  list: {
    title: '通常商品販売',
    label: {
      note_tax: '(税込)',
    },
  },
};
