<template>
  <v-app id="main-app">
    <auth-header></auth-header>
    <v-main id="content-wrapper">
      <v-container
        fluid
        class="main-container main-container--auth"
        :class="{'main-container--center' : $route.name === 'login'}"
      >
        <KeepAlive v-if="$route.meta.keep_alive">
          <router-view :key="$route.fullPath"></router-view>
        </KeepAlive>
        <router-view v-else :key="$route.fullPath"></router-view>
      </v-container>
    </v-main>
    <user-footer></user-footer>
  </v-app>
</template>

<script>
import AuthHeader from '@/layouts/auth/Header.vue';
import UserFooter from '@/layouts/user/Footer.vue';

export default {
  name: 'AuthLayout',
  components: {
    AuthHeader,
    UserFooter,
  },
};
</script>

<style lang="css" scoped>
.wrap_content {
  height: 100vh;
}
</style>
