<template>
  <div class="wrap_content"></div>
</template>

<script>
export default {
  name: 'InitLayout',
};
</script>

<style lang="css" scoped></style>
