export default {
  label: {
    title: '配送方法選択',
    name: 'お名前',
    post_code: '郵便番号',
    prefecture: '都道府県',
    address_1: '住所',
    address_2: '建物など',
    phone_number: '電話番号',
    delivery_charge: '配送料：',
  },
  radio: {
    delivery: {
      registered_address: '登録先の住所へ配送',
      new_address: 'それ以外の住所へ配送',
    },
  },
  message: {
    note: 'お届け先を入力してください。',
  },
};
