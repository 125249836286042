<template>
  <v-app id="main-app">
    <user-header></user-header>
    <v-main id="content-wrapper" class="d-flex flex-column">
      <v-container
        fluid
        class="main-container"
        :class="containerClass"
        @click="hideOptionMenu()"
      >
        <KeepAlive v-if="$route.meta.keep_alive">
          <router-view :key="$route.fullPath"></router-view>
        </KeepAlive>
        <router-view v-else :key="$route.fullPath"></router-view>
      </v-container>
      <div v-if="is_open" class="not__active-content"></div>
    </v-main>
    <user-footer></user-footer>
  </v-app>
</template>

<script>
import UserHeader from '@/layouts/user/Header.vue';
import UserFooter from '@/layouts/user/Footer.vue';
import { mapGetters } from 'vuex';

export default {
  name: 'UserLayout',
  components: {
    UserHeader,
    UserFooter,
  },
  created () {
    this.getStudentForCustomerId();
  },
  data () {
    return {
      containerWidth: {
        product_collection: '950',
        product_detail: '950',
      },
    };
  },
  methods: {
    hideOptionMenu () {
      this.$store.dispatch('headerStore/onToggle', false);
    },
    getStudentForCustomerId () {
      this.$store.dispatch('userStore/getStudentForCustomerId');
    },
  },
  computed: {
    ...mapGetters({
      is_open: 'headerStore/is_open',
    }),
    containerClass () {
      const maxWidth = this.containerWidth[this.$route.name] || null;

      return maxWidth ? `maxw-${maxWidth}` : '';
    },
  },
};
</script>

<style scoped>
  .not__active-content {
    background: rgba(217, 217, 217, 0.4);
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;
  }

</style>
