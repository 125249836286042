export default {
  title: {
    header: 'プロフィール',
    user: '保護者情報',
    student: '生徒情報',
  },
  form_user: {
    login_id: 'ログインID',
    last_name: '姓',
    first_name: '名',
    kana_last_name: 'セイ',
    kana_first_name: 'メイ',
    postal_code: '郵便番号',
    prefecture: '都道府県',
    address_1: '住所',
    address_2: '建物など',
    phone_number: '電話番号',
  },
  form_student: {
    last_name: '姓',
    first_name: '名',
    kana_last_name: 'セイ',
    kana_first_name: 'メイ',
    birthday: '生年月日',
    gender: '性別',
    school: '団体',
  },
  msg: {
    format_email: '正しいメールアドレスの形式を入力してください。',
    duplicate_login_id: 'ログインIDは既にシステムに存在しています。別のログインIDを入力してください。',
  },
  gender: {
    male: '男',
    female: '女',
  },
};
