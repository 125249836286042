export default {
  input_mail: {
    title: 'パスワードをお忘れの方',
    note: 'ご登録先のメールアドレスに、パスワード再設定の案内をお送りします。',
    label: {
      email: 'メールアドレス',
    },
    btn: {
      submit: '送信',
      back: '戻る',
      go_login: 'ログインへ',
    },
    messages: {
      type_email: 'メールアドレスが有効ではありません。',
      notice_after_submit: 'パスワードの設定について案内を送信しました。',
    },
  },
  input_password: {
    title: 'パスワード再設定',
    note: 'パスワードは、8~12文字で半角英字と半角数字の両方を含めて登録してください。',
    label: {
      password: '新しいパスワード',
      password_confirmation: '新しいパスワード（確認）',
    },
    btn: {
      submit: '登録',
    },
    messages: {
      type_email: 'メールアドレスが有効ではありません。',
      notice_after_submit: 'パスワードが設定されました。',
      type_password: '新しいパスワードが有効ではありません。',
      password_not_match: '新しいパスワードと新しいパスワード（確認）は同じ値を入力してください。',
      note_effect: 'パスワードリセットトークンは無効です。',
    },
  },
};
