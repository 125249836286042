<template>
  <div class="text-center">
    <v-snackbar
      v-touch:swipe="swipeHandler"
      v-model="snackbar"
      :timeout="timeout"
      :class="classList"
      top="top"
      right="right"
    >
      {{ snackMessage }}
    </v-snackbar>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';

const SUCCESS = 1;
const ERROR = 2;
export default {
  name: 'SnackBar',
  data () {
    return {
      snackbar: false,
      timeout: 2000,
      type: '',
      snackMessage: '',
    };
  },
  methods: {
    swipeHandler () {
      this.snackbar = false;
    },
  },
  computed: {
    ...mapGetters({
      success: 'snackbar/success',
      error: 'snackbar/error',
      message: 'snackbar/message',
    }),
    classList () {
      let classList = '';
      switch (this.type) {
        case SUCCESS:
          classList = 'v-snack--success';
          break;
        case ERROR:
          classList = 'v-snack--error';
          break;
        default:
          break;
      }

      return classList;
    },
  },
  watch: {
    success: {
      handler () {
        this.type = SUCCESS;
        this.snackMessage = this.message ? this.message : this.$t('common.message.success');
        this.snackbar = true;
      },
      deep: true,
    },
    error: {
      handler () {
        this.type = ERROR;
        this.snackMessage = this.message ? this.message : this.$t('common.message.error');
        this.snackbar = true;
      },
      deep: true,
    },
  },
};
</script>
