import http from '@/services/http';
import apiEndpoints from '@/services/apiEndpoints';

const state = {
  cartItems: [],
  cartItemQuantity: 0,
};

const mutations = {
  SET_CART_ITEMS (state, payload) {
    state.cartItems = payload;
  },
  ADD_CART_ITEMS (state, payload) {
    state.cartItems.push(...payload);
  },
  SET_CART_ITEM_QUANTITY (state, payload) {
    state.cartItemQuantity = payload;
  },
};

const getters = {
  cartItems: state => state.cartItems,
  cartItemQuantity: state => state.cartItemQuantity,
  student (state, getters, rootState, rootGetters) {
    return rootGetters['userStore/student'];
  },
};

const actions = {
  async addToCart ({ commit }, payload) {
    const res = await http.post(apiEndpoints.addToCart, payload);
    const { data } = res;

    if (data.result && data.result.success) {
      commit('SET_CART_ITEMS', data.result.cart.cart_items);
      commit('SET_CART_ITEM_QUANTITY', data.result.cart.cart_items ? data.result.cart.cart_items.length : 0);
    }

    return data.result;
  },
  resetCartItems ({ commit }) {
    commit('SET_CART_ITEMS', []);
    commit('SET_CART_ITEM_QUANTITY', 0);
  },
  async getCartItems ({ commit, getters }) {
    const res = await http.get(apiEndpoints.getListCartItems, {
      student_id: getters.student.id,
    });

    const { status, data } = res;
    if (status) {
      commit('SET_CART_ITEMS', data.result);
      commit('SET_CART_ITEM_QUANTITY', data.result ? data.result.length : 0);
      return data.result;
    }
  },
  async updateQuantity (_, payload) {
    return await http.put(apiEndpoints.updateQuantity, payload);
  },
  async deleteCartItem (_, payload) {
    return await http.delete(apiEndpoints.deleteCartItem.replace('{id}', payload));
  },
};
export default {
  actions,
  getters,
  state,
  mutations,
};
