export default {
  message: {
    confirm: '以下の内容でよろしいでしょうか？',
  },
  table: {
    header: {
      product: '商品',
      quantity: '数量',
      total: '金額',
    },
  },
  button: {
    buy: '注文する',
  },
  label: {
    total: '合計金額',
  },
};
