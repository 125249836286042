import axios from 'axios';

const ERROR_CODE_VALIDATE = 422;
const ERROR_CODE_NOT_FOUND = 404;
const ERROR_CODE_UNAUTHORIZED = 401;
const ERROR_CODE_BAD_REQUEST = 400;
const ERROR_CODE_FORBIDDEN = 403;

const payment = axios.create({
  baseURL: process.env.VUE_APP_GMO_URL_GET_TOKEN_CARD,
  timeout: 20000,
  headers: {
    'Content-Type': 'application/json',
    Authorization: 'Bearer ' + process.env.VUE_APP_GMO_PUBLIC_KEY,
  },
});
payment.interceptors.response.use((response) => response, (e) => {
  const errorCode = e.response ? e.response.status : 500;
  switch (errorCode) {
    case ERROR_CODE_FORBIDDEN:
    case ERROR_CODE_UNAUTHORIZED:
    case ERROR_CODE_VALIDATE:
    case ERROR_CODE_NOT_FOUND:
    case ERROR_CODE_BAD_REQUEST:
      return {
        status: false,
        errorCode: errorCode,
        data: e.response ? e.response.data.errors : 'error',
        message: e.response ? e.response.data.message : 'Unknow',
        error: e,
      };
  }
  return e;
});

export default {
  post (payload) {
    return payment.post('', payload);
  },
};
