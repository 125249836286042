<template>
  <div class="header">
    <div class="header__wrapper">
      <div class="header__logo">
        <router-link :to="{ name: 'top' }">
          <img class="only-sp" src="@/assets/images/img_logo_sp.png" alt="img_logo_sp"/>
          <img class="only-pc" src="@/assets/images/img_logo.png" alt="img_logo"/>
        </router-link>
      </div>
      <div class="header__option">
        <div class="item__option list__student">
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <div
                color="primary"
                dark
                v-bind="attrs"
                v-on="on"
                class="show__name"
              >
                <div class="student-name">{{ student.name }}</div>
                <svg-icon class="icon__detail" icon="triagle_down"></svg-icon>
              </div>
            </template>
            <v-list>
              <v-list-item
                v-for="(item, index) in studentOptions"
                :key="index"
                :class="{'border__item': index != 0}"
              >
                <v-list-item-title
                  @click="getAndSetStudentNameId(item.id, item.first_name, item.last_name)"
                  class="item-student"
                  :class="{'item__child_active': student.id == item.id}"
                >
                  {{ item.full_name }}
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>

        <router-link
          class="header-menu-item"
          :class="{ 'header-menu-item--active' : $route.path.includes('top') }"
          :to="{ name: 'top' }"
        >
          <div class="header-menu-item__name">{{ $t('header.item.top_page') }}</div>
        </router-link>
        <router-link
          class="header-menu-item"
          :class="{ 'header-menu-item--active' : $route.path.includes('my-page') }"
          :to="{ name: 'my_page' }"
        >
          <div class="header-menu-item__name">{{ $t('header.item.my_page') }}</div>
        </router-link>
        <router-link
          class="header-menu-item"
          :class="{ 'header-menu-item--active' : $route.path.includes('faq') }"
          :to="{ name: 'faq' }"
        >
          <div class="header-menu-item__name">{{ $t('header.item.faq') }}</div>
        </router-link>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              :disabled="$route.name === 'product_collection'"
              class="btn__icon btn__cart mr-3"
              @click="clickCart"
              v-bind="attrs"
              v-on="on"
            >
              <svg-icon icon="cart"></svg-icon>
              <span
                class="cart-amount"
                v-if="cartItemQuantity"
              >
                {{ cartItemQuantity < 100 ? cartItemQuantity : '99+' }}
              </span>
            </v-btn>
          </template>
          <span>{{ $t('common.button.cart') }}</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="btn__icon mr-3"
              @click="logout()"
              v-bind="attrs"
              v-on="on"
            >
              <svg-icon icon="logout"></svg-icon>
            </v-btn>
          </template>
          <span>{{ $t('common.button.logout') }}</span>
        </v-tooltip>
      </div>

      <div class="header__option-sp">
        <div class="item__option list__student">
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <div
                color="primary"
                dark
                v-bind="attrs"
                v-on="on"
                class="show__name"
              >
                <div class="student-name">{{ student.name }}</div>
                <svg-icon class="icon__detail" icon="triagle_down"></svg-icon>
              </div>
            </template>
            <v-list>
              <v-list-item
                v-for="(item, index) in studentOptions"
                :key="index"
                :class="{'border__item': index != 0}"
              >
                <v-list-item-title
                  @click="getAndSetStudentNameId(item.id, item.first_name, item.last_name)"
                  class="item-student"
                  :class="{'item__child_active': student.id == item.id}"
                >
                  {{ item.full_name }}
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
        <v-btn
          @click="clickCart"
          :disabled="$route.name === 'product_collection'"
          class="btn__icon btn__cart mr-3 icon__cart"
        >
          <svg-icon icon="cart"></svg-icon>
          <span
            class="cart-amount"
            v-if="cartItemQuantity"
          >
            {{ cartItemQuantity < 100 ? cartItemQuantity : '99+' }}
          </span>
        </v-btn>
        <v-menu
          bottom
          origin="center center"
          transition="scale-transition"
          content-class="header-dropdown"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              class="btn__icon"
              elevation="0"
            >
              <svg-icon icon="menu"></svg-icon>
            </v-btn>
          </template>

          <v-list>
            <v-list-item-group>
              <v-list-item
                v-for="(item, index) in menuItems"
                :key="index"
              >
                <div
                  class="header-dropdown-item"
                  :class="{ 'header-dropdown-item--active' : $route.path.includes(item.path) }"
                  @click="clickHeaderMenu(item)"
                >
                  <svg-icon :icon="item.icon"></svg-icon>
                  <svg-icon
                    v-if="item.action === 'link'"
                    class="icon--active"
                    :icon="`${item.icon}_active`"
                  ></svg-icon>
                  <span class="header-dropdown-item__name">{{ item.name }}</span>
                </div>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-menu>
      </div>
    </div>
    <user-dialog
      ref="dialog_alert_change_student"
    >
      <template slot="header">
        <div
          class="alert-message"
          v-html="$t('product.dialog.change_student_alert.message')"
        ></div>
      </template>
    </user-dialog>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
export default {
  name: 'UserHeader',
  data () {
    return {
      showStudent: false,
      menuItems: [
        {
          name: this.$t('header.item.top_page'),
          action: 'link',
          router: 'top',
          path: '/top',
          icon: 'menu_top',
        },
        {
          name: this.$t('header.item.my_page'),
          action: 'link',
          router: 'my_page',
          path: '/my-page',
          icon: 'menu_my_page',
        },
        {
          name: this.$t('header.item.faq'),
          action: 'link',
          router: 'faq',
          path: '/faq',
          icon: 'menu_faq',
        },
        {
          name: this.$t('header.item.logout'),
          action: 'logout',
          icon: 'menu_logout',
        },
      ],
    };
  },
  async created () {
    await this.getCartItems();
  },
  computed: {
    ...mapGetters({
      user: 'userStore/user',
      student: 'userStore/student',
      is_open: 'headerStore/is_open',
      cartItems: 'cartStore/cartItems',
      cartItemQuantity: 'cartStore/cartItemQuantity',
    }),
    studentOptions () {
      if (!this.user.students || !this.user.students.length) {
        return [];
      }
      const studentOptions = this.user.students.map(item => {
        return {
          ...item,
          full_name: (item.last_name ? item.last_name + ' ' : '') + (item.first_name || ''),
        };
      });

      return studentOptions;
    },
  },
  methods: {
    ...mapActions({
      getCartItems: 'cartStore/getCartItems',
      resetCartItems: 'cartStore/resetCartItems',
    }),
    async getAndSetStudentNameId (studentId, firstName, lastName) {
      if (this.student.id !== studentId) {
        if (this.cartItemQuantity) {
          this.$refs.dialog_alert_change_student.open();
        } else {
          this.currentStudentId = studentId;
          const studentName = lastName + ' ' + firstName;
          this.$store.dispatch('userStore/setInfoStudent', { studentId, studentName });
          await this.getCartItems();
          if (this.$route.name !== 'top') {
            this.$router.push({ name: 'top' });
          }
        }
      }
    },
    async logout () {
      const status = await this.$store.dispatch('userStore/logoutUser');
      if (status) {
        this.$router.push({ name: 'login' });
        this.resetCartItems();
      }
    },
    onClickShowMenuSp () {
      this.$store.dispatch('headerStore/onToggle', true);
    },
    onClickHideMenuSp () {
      this.$store.dispatch('headerStore/onToggle', false);
    },
    clickHeaderMenu (item) {
      if (item.action === 'link') {
        this.$router.push({ name: item.router });
      } else if (item.action === 'logout') {
        this.logout();
      }
    },
    clickCart () {
      this.$router.push({ name: 'cart' });
    },
  },
};
</script>

<style scoped>

.header__option {
  width: 60%;
  height: 40px;
  display: flex;
}

.item__option {
  color: #102A56;
  font-size: 24px;
  margin: 0 5%;
  cursor: pointer;
  font-weight: 600;
  white-space: nowrap;
}

.icon__detail {
  margin-left: 10px;
}

.list__child {
  background: #ffff;
  min-height: 100px;
  border-radius: 7px;
}

.item__child {
  height: 55px;
  display: flex;
  align-items: center;
  margin: 0 10px;
  justify-content: center;
  font-size: 20px;
  color: #666666;
}

.border__item {
  border-top: 1px solid #CECECE;
}

.item__child_active {
  color: #F6AD49;
}

.link__item {
  text-decoration: none;
}

.header__wrapper {
  max-width: 1200px;
  margin: 0 auto;
  position: relative;
}

.header__wrapper::after {
  content: '';
  display: block;
  height: 5px;
  width: 100vw;
  background: #F6AD49;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}

.icon-text {
  margin-left: 20px;
}

.icon__menu {
  display: none;
}

.header__option-sp {
  display: none;
}

.header__option-sp .item__option {
  display: none;
}

.header__option-sp .btn__icon {
  display: none;
}

.show__name {
  display: flex;
  min-width: 140px;
  font-size: 26px;
}

.icon__detail {
  display: flex;
  align-items: center;
}

.icon__detail img {
  height: 20px;
}

.item-student {
  cursor: pointer;
  font-size: 20px;
  line-height: 1.5 !important;
}

.list__student {
  width: calc(100vw - 190px);
  display: flex;
  justify-content: flex-end;
}

.list__student .show__name {
  display: flex;
  justify-content: flex-end;
}

.list__student .show__name .student-name {
  font-size: 20px;
  max-width: calc(100% - 30px);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: #102A56;
}

.header__wrapper .router-link-active {
  background: #F6AD49;
  text-decoration: none;
  height: 67px;
}

@media screen and (max-width: 1000px) {
  .header__wrapper .router-link-active {
    background: none;
    height: 30px;
  }

  .header__wrapper .router-link-active .text__label {
    background: none;
    text-decoration: none;
    color: #F6AD49 !important;
  }
}

@media screen and (min-width: 960px) {
  .list__student .show__name .student-name {
    font-size: 20px;
    max-width: 300px;
    line-height: 40px;
  }
}

.header-menu-item {
  padding: 0 20px;
  position: relative;
  font-size: 20px;
  font-weight: bold;
  text-decoration: none;
}

.header-menu-item.header-menu-item--active::before {
  content: '';
  display: block;
  background: #F6AD49;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  height: 80px;
  width: 100%;
}

.header-menu-item__name {
  font-size: 20px;
  font-weight: bold;
  color: #102A56;
  white-space: nowrap;
  text-decoration: none;
  position: relative;
  z-index: 2;
  padding-top: 5px;
}

.header-menu-item.header-menu-item--active .header-menu-item__name {
  color: #fff;
}

@media screen and (max-width: 1800px) {
  .link__item {
    min-width: 12%;
  }
}

@media screen and (max-width: 1000px) {
  .header__logo {
    height: 35px;
    width: 15%;
  }

  .icon__menu {
    display: block;
  }

  .header__option-sp {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 100%;
    margin-right: 10px;
  }

  .header__option {
    display: none !important;
  }

  .header__option .item__option {
    display: none;
    white-space: nowrap;
  }

  .header__option .btn__icon {
    display: none;
  }

  .header__option-sp .btn__icon {
    display: block;
  }

  .header__option-sp .item__option {
    display: block;
    height: 30px;
    color: #286AD7;
  }

  .header__option-sp .icon__menu .item__option {
    display: flex;
    height: 38px;
    color: #666666;
    font-weight: 500;
    justify-content: left;
    align-items: center;
  }

  .icon__menu, .icon__cart {
    display: flex;
  }

  .icon__cart {
    display: flex !important;
  }

  .list__child {
    position: absolute;
  }

  .option__menu-sp {
    background: #ffff;
    position: absolute;
    top: 30px;
    right: 0px;
    width: 150px;
    height: 150px;
    border-radius: 7px;
    z-index: 2;
  }

  .item__option {
    font-size: 20px;
  }

  .text__label {
    font-size: 20px;
    color: #666666;
    font-weight: 500;
    margin-left: 10px;
  }
}

.v-menu__content .v-list {
  max-height: 60vh;
  background: #fff;
}

.v-menu__content .v-list .v-list-item {
  background: #fff;
}
</style>
