export default {
  label: {
    height_cm: '身長(cm)',
    weight_kg: '体重(kg)',
    shoulder_width_cm: '肩幅(cm)',
    sleeve_length_cm: '袖丈(cm)',
    inseam: '股下(cm)',
    girder_length_cm: '桁丈(cm)',
    body_length_cm: '身丈(cm)',
    chest: '胸囲(cm)',
    waist: 'ウエスト(cm)',
    hips: 'ヒップ(cm)',
    arm_circumference_cm: '腕まわり(cm)',
    thigh_circumference_cm: 'ももまわり(cm)',
    neck_circumference_cm: '首まわり(cm)',
  },
};
