<template>
  <div class="header header--auth">
    <div class="header__logo">
      <img src="@/assets/images/img_logo.png" alt="logo"/>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AuthHeader',
};
</script>

<style scoped></style>
