import http from '@/services/http';
import apiEndpoints from '@/services/apiEndpoints';
import { cloneDeep } from 'lodash';

const actions = {
  async createStudentSize (_, payload) {
    return await http.post(apiEndpoints.createStudentSize, payload);
  },
  async getStudentSizeById (_, payload) {
    return await http.get(apiEndpoints.getStudentSizeById.replace('{student_id}', payload));
  },
  async updateStudentSize (_, payload) {
    const payloadCopy = cloneDeep(payload);
    const studentId = payloadCopy.student_id;
    delete payloadCopy.student_id;
    return await http.put(apiEndpoints.updateStudentSize.replace('{student_id}', studentId), payloadCopy);
  },
};
export default {
  actions,
};
