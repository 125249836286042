import http from '@/services/http';
import apiEndpoints from '@/services/apiEndpoints';

const actions = {
  async getListFaq (_) {
    const response = await http.get(apiEndpoints.getListFaq);
    if (response.status && response.data) return response.data;
    return [];
  },
};
export default {
  actions,
};
