import http from '@/services/http';
import apiEndpoints from '@/services/apiEndpoints';

const actions = {
  async getContentBySlug (_, slug) {
    const pageResponse = await http.get(apiEndpoints.getPageBySlug.replace('{slug}', slug));
    if (pageResponse.status && pageResponse.data) return pageResponse.data;
    return [];
  },
};

export default {
  actions,
};
