<template>
  <div class="text-center">
    <v-dialog
      v-model="dialog"
      :class="dialogClass"
      :content-class="classList.join(' ')"
      width="auto"
      persistent
    >
      <v-card>
        <v-card-title
           v-if="hasHeaderSlot"
          class="dialog-header"
          :class="{
            'dialog-header--title-only' : !headerClose,
            'dialog-header--border': showHeaderLine,
            'dialog-header--center' : hasBodySlot,
          }"
        >
          <h3 class="dialog-header__title">
            <slot name="header"></slot>
          </h3>
          <v-btn
            v-if="headerClose"
            @click="close"
            class="btn__icon"
            :ripple="false"
            :elevation="0"
          >
            <svg-icon icon="close_circle"></svg-icon>
          </v-btn>
        </v-card-title>
        <v-card-text v-if="hasBodySlot" class="dialog-body">
          <slot name="body"></slot>
        </v-card-text>
        <v-card-actions v-if="hasFooterSlot" class="dialog-footer">
          <slot name="footer"></slot>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
const DIALOG_TYPE_MESSAGE = 0;
const DIALOG_TYPE_FORM = 1;

export default {
  name: 'UserDialog',
  props: {
    classList: { type: [String, Array, Object], require: false, default: () => [] },
    dialogType: { type: Number, default: DIALOG_TYPE_MESSAGE },
    headerClose: { type: Boolean, default: true },
    showHeaderLine: { type: Boolean, default: false },
  },
  data () {
    return {
      dialog: false,
    };
  },
  methods: {
    open () {
      this.dialog = true;
    },
    close () {
      this.dialog = false;
      this.$emit('close');
    },
  },
  computed: {
    dialogClass () {
      return {
        'minw-1000': this.dialogType === DIALOG_TYPE_FORM,
      };
    },
    hasHeaderSlot () {
      return !!this.$slots.header;
    },
    hasBodySlot () {
      return !!this.$slots.body;
    },
    hasFooterSlot () {
      return !!this.$slots.footer;
    },
  },
};
</script>
