export default {
  title: {
    header: '買い物履歴',
    label_receipt_btn: '領収書',
    confirm: '注文情報確認',
  },
  table: {
    shipping_fee: '配送料',
    total_price: '合計金額',
    payment_status: '支払状況',
    delivery_status: '配送状況',
  },
  file: {
    name: '領収書',
  },
  label: {
    home_delivery: '自宅配送',
    product: '商品',
    quantity: '数量',
    amount: '金額',
    tax_included: '(税込)',
    product_total_price: '商品合計金額',
    delivery_charge: '配送料',
    postal_mark: '〒',
    address: '〒{address}',
    quantity_sp: '数量: {quantity}',
    total: '合計金額',
    payment: 'カードで支払う',
  },
  message: {
    note: '※こちらの情報でよろしければ次へのボタンをクリックしてください。決済画面へ進みます。',
  },
  payment_status: {
    unpaid: '未払',
    on_hold: '保留',
    paid: '支払済',
    cancel: 'キャンセル',
  },
  delivery_status: {
    undispatched: '未発送',
    arranged: '手配中',
    completion: '完了',
    on_hold: '保留',
  },
};
