export default {
  title_page: 'お支払い方法選択',
  credit_card_payment: 'クレジットカード決済',
  button: {
    go_to_top: 'トップ',
  },
  message: {
    error_date: '有効期限を正しく選択してください。',
    error_payment: 'エラーが発生しました。',
    success_payment: 'ご注文いただき、ありがとうございました。<br>ご登録いただいたメールアドレスに、注文完了のメールをお送りしておりますので、ご確認ください。',
    error_holder_name: '半角英字で入力してください。',
    card_error: 'カード認証を正しく行うことが出来ませんでした。詳しい原因につきましてはカード会社にお問い合わせください。',
    payemnt_completed: '決済は既に完了しています',
  },
  label: {
    select_cart: '以下のクレジットカードがご利用いただけます。',
    card_number: 'カード番号',
    expire: '有効期限',
    card_name: 'カード名義人名',
    security_code: 'セキュリティコード',
    bank_transfer: '銀行振込',
    bank_name: '銀行',
    bank_branch: '銀行支店',
    total_price: '合計金額',
  },
  btn: {
    submit_payment: '注文する',
  },
  placeholder: {
    card_no: '1234567890123456',
    security_code: '123',
    card_name: 'TARO YAMADA',
  },
};
