import moment from 'moment';
import { findIndex } from 'lodash';
import constant from '@/configs/constant';

export function formatDate (date) {
  if (date) {
    return moment(date).format('YYYY/MM/DD');
  }
  return '';
}

export function firstImageProduct (images) {
  if (images && images[0] && images[0].path) {
    return process.env.VUE_APP_FILE_URL + '/' + images[0].path.replace('public/', '');
  }
  return '';
}

export function formatGender (type) {
  const index = findIndex(constant.genders, function (obj) { return obj.value === type; });
  if (index !== -1) {
    return constant.genders[index].text;
  }
}

export function filteredJapanPrice (value) {
  if (isNaN(value)) {
    return '';
  }
  return new Intl.NumberFormat('ja-JP', { style: 'currency', currency: 'JPY' }).format(value);
}
