<template>
  <div class="footer">
    <div class="footer-menu">
      <div
        class="footer-menu__item"
        v-for="(item, index) in menuItems"
        :key="index">
        <router-link :to="{name : item.router_name}">{{item.name}}</router-link>
      </div>
    </div>
    <div class="footer__copy-right">{{ $t('common.footer.copy_right') }}</div>
    <!-- Dialog confirm left product collection set -->
    <user-dialog
      ref="dialog_confirm_leave_set"
      :headerClose="false"
    >
      <template slot="header">
        <p class="text-left mb-0" v-html="$t('product.dialog.confirm_leave_set.message')"></p>
      </template>
      <template slot="footer">
        <v-btn
          class="btn btn__secondary mr-4"
          @click="closeConfirmDialog"
          >{{ $t('common.button.back') }}</v-btn
        >
        <v-btn
          class="btn btn__primary"
          @click.prevent="confirmNext"
          >{{ $t('common.button.yes') }}</v-btn
        >
      </template>
    </user-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'UserFooter',
  data () {
    return {
      menuItems: [
        {
          router_name: 'privacy-policy',
          name: this.$t('common.footer.menu.privacy_policy'),
        },
        {
          router_name: 'terms-of-use',
          name: this.$t('common.footer.menu.terms_of_use'),
        },
        {
          router_name: 'return-policy',
          name: this.$t('common.footer.menu.return_policy'),
        },
        {
          router_name: 'commercial-transaction-law',
          name: this.$t('common.footer.menu.specified_commercial'),
        },
        {
          router_name: 'how-to-cancel-spam',
          name: this.$t('common.footer.menu.how_to_cancel_spam'),
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      confirmRoute: 'collectionStore/confirmRoute',
      isLeaveConfirmed: 'collectionStore/isLeaveConfirmed',
      isShowLeaveSetConfirm: 'collectionStore/isShowLeaveSetConfirm',
    }),
  },
  methods: {
    ...mapActions({
      setIsLeaveConfirmed: 'collectionStore/setIsLeaveConfirmed',
      setShowLeaveSetConfirm: 'collectionStore/setShowLeaveSetConfirm',
    }),
    closeConfirmDialog () {
      this.$refs.dialog_confirm_leave_set.close();
      this.setShowLeaveSetConfirm(false);
    },
    confirmNext () {
      this.closeConfirmDialog();
      this.setIsLeaveConfirmed(true);
      this.nextScreen();
    },
    nextScreen () {
      if (this.confirmRoute.name) {
        this.$router.push({
          name: this.confirmRoute.name,
          params: this.confirmRoute.params,
        });
      }
    },
  },
  watch: {
    isShowLeaveSetConfirm: {
      handler (newVal) {
        if (newVal) {
          this.$refs.dialog_confirm_leave_set.open();
        }
      },
    },
  },
};
</script>

<style scoped lang="scss">
.footer-menu__item {
  & a {
    color: #fff;
    text-decoration: none;
    font-size: 12px;
  }
}
@media screen and (min-width: 960px){
.footer-menu__item {
  & a {
    font-size: 16px;
  }
}
}
</style>
