const state = {
  isShowLeaveSetConfirm: false,
  isLeaveConfirmed: false,
  confirmRoute: null,
};

const mutations = {
  SET_SHOW_LEAVE_SET_CONFIRM (state, payload) {
    state.isShowLeaveSetConfirm = payload;
  },
  SET_IS_LEAVE_CONFIRMED (state, payload) {
    state.isLeaveConfirmed = payload;
  },
  SET_CONFIRM_ROUTE (state, payload) {
    state.confirmRoute = payload;
  },
};

const getters = {
  isShowLeaveSetConfirm: state => state.isShowLeaveSetConfirm,
  isLeaveConfirmed: state => state.isLeaveConfirmed,
  confirmRoute: state => state.confirmRoute,
};

const actions = {
  setIsLeaveConfirmed ({ commit }, payload) {
    commit('SET_IS_LEAVE_CONFIRMED', payload);
  },
  setShowLeaveSetConfirm ({ commit }, payload) {
    commit('SET_SHOW_LEAVE_SET_CONFIRM', payload);
  },
  setConfirmRoute ({ commit }, payload) {
    commit('SET_CONFIRM_ROUTE', payload);
  },
  reset ({ commit }) {
    commit('SET_IS_LEAVE_CONFIRMED', false);
    commit('SET_SHOW_LEAVE_SET_CONFIRM', false);
    commit('SET_CONFIRM_ROUTE', null);
  },
};

export default {
  actions,
  getters,
  state,
  mutations,
};
