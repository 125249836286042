import Vue from 'vue';
import {
  ValidationProvider,
  ValidationObserver,
  setInteractionMode,
  extend,
} from 'vee-validate';
import * as rules from 'vee-validate/dist/rules';
import i18n from '@/locales/i18n';

Object.keys(rules).forEach(rule => {
  extend('kana_full_width', {
    validate (value) {
      const newVal = value.replace(/[\u200B-\u200D\uFEFF]/g, '');
      const reg = /^([ぁ-ゔァ-ヴー]*)+$/;
      return {
        valid: reg.test(newVal),
      };
    },
    message: i18n.t('validate.messages.kana_full_width'),
  });
  extend(rule, {
    ...rules[rule],
    message: i18n.t('validate.messages.' + rule),
  });
  extend('half_width', {
    validate (value) {
      const regHalfWithAlphabet = /^[a-zA-Z0-9]+$/;
      return {
        valid: regHalfWithAlphabet.test(value),
      };
    },
    message: i18n.t('validate.messages.type_haft_with'),
  });
  extend('half_width_number', {
    validate (value) {
      const regHalfWithNumber = /^[-]?[0-9]+(\.[0-9]+)?$/;
      return {
        valid: regHalfWithNumber.test(value),
      };
    },
    message: i18n.t('validate.messages.type_haft_with_number'),
  });
  extend('type_password', {
    validate (value) {
      const regAlphabetAndNumber = /^(?=.*?\d)(?=.*?[a-zA-Z])[a-zA-Z\d]+$/;
      return {
        valid: (regAlphabetAndNumber.test(value) && value.length >= 8 && value.length <= 12),
      };
    },
    message: i18n.t('validate.messages.type_password'),
  });
  extend('phone_number', {
    validate (value) {
      const regPhoneNumber = /^0[0-9]{1,3}-[0-9]{2,4}-[0-9]{3,4}$/;
      return {
        valid: regPhoneNumber.test(value) && (value.length === 12 || value.length === 13),
      };
    },
    message: i18n.t('validate.messages.error_phone_number'),
  });
  extend('range', {
    validate (value, { min, max }) {
      return value >= min && value <= max;
    },
    params: ['min', 'max'],
    message: i18n.t('validate.messages.range_number'),
  });
  extend('card_no_format', {
    validate (value) {
      const reg = /^([0-9\s]+)$/;
      return {
        valid: reg.test(value),
      };
    },
    message: i18n.t('validate.messages.card_no'),
  });
});
setInteractionMode('passive');
Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);

export default {};
