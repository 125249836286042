export default {
  basic: {
    title: '新規会員登録',
    label: {
      group_code: '認証コード',
      email: 'メールアドレス',
    },
    messages: {
      group_code: '認証コード',
      email: 'メールアドレス',
      email_max_255: 'メールアドレスが最大文字数の255文字を超過しています。',
      notice_after_submit: 'ご登録先のメールアドレスに、新規会員登録の案内を送信しました。',
      type_not_email: 'メールアドレスが有効ではありません。',
    },
  },
  info_customer: {
    title_page: '団体名',
    title_notice: '新規会員登録',
    note: '保護者情報を入力してください。',
    label: {
      last_name: '姓',
      first_name: '名',
      last_name_kana: 'セイ',
      first_name_kana: 'メイ',
      postal_code: '郵便番号',
      prefecture: '都道府県',
      address_1: '住所',
      address_2: '建物など',
      phone_number: '電話番号',
      external_characters: '外字を含む方はこちら',
    },
    messages: {
      expireUrl: 'このURLは無効です。',
    },
  },
  info_student: {
    title_page: '団体名',
    note: '生徒情報を入力してください。',
    label: {
      last_name: '姓',
      first_name: '名',
      kana_last_name: 'セイ',
      kana_first_name: 'メイ',
      birthday: '生年月日',
      gender: '性別',
    },
    messages: {
      gender_empty: '性別を入力してください。',
      validate_empty: 'この項目は必須です。',
    },
  },
  setting_password: {
    note: 'パスワード設定',
    label: {
      password: 'パスワード',
      password_confirmation: 'パスワード（確認用）',
      password_confirmation_notice: 'パスワード（確認）',
    },
    messages: {
      note_password: 'パスワードは、8~12文字で半角英字と半角数字の両方を含めて登録してください。',
      note_password_confirmation: 'パスワードをメモしてください。',
      validate_empty: 'この項目は必須です。',
    },
  },
  confirm_info: {
    note: '以下の内容で登録してよろしいでしょうか。',
    title: {
      customer: '保護者情報',
      student: '生徒情報',
    },
    label: {
      full_name: '氏名',
      name_kana: '氏名（カナ）',
      postal_code: '郵便番号',
      prefecture: '都道府県',
      address_1: '住所',
      address_2: '建物など',
      phone_number: '電話番号',
      birthday: '生年月日',
      gender: '性別',
      number_tester: '受験番号',
    },
    messages: {
      note_password: 'パスワードは、8~12文字で半角英字と半角数字の両方を含めて登録してください。',
      note_password_confirmation: 'パスワードをメモしてください。',
      validate_empty: 'この項目は必須です。',
      type_password: '新しいパスワードが有効ではありません。',
      register_success: '正常に登録しました。',
      register_error: 'エラーが発生しました。',
    },
  },
};
