import http from '@/services/http';
import apiEndpoints from '@/services/apiEndpoints';
import router from '@/router';

const getDefaultState = () => {
  return {
    user: {},
    token: '',
    student: {
      id: null,
      name: null,
    },

    // use register customer
    group: {},
    infoCustomer: {},
    allPassword: {},
    infoStudent: {},
    verificationForm: {},
  };
};

const state = getDefaultState();
const getters = {
  user (state) {
    return state.user;
  },
  token (state) {
    return state.token;
  },
  student (state) {
    return state.student;
  },
  infoCustomer (state) {
    return state.infoCustomer;
  },
  infoStudent (state) {
    return state.infoStudent;
  },
  group (state) {
    return state.group;
  },
  allPassword (state) {
    return state.allPassword;
  },
  verificationForm (state) {
    return state.verificationForm;
  },
  students (state) {
    return state.user.students.map((student) => {
      return {
        text: student.last_name + ' ' + student.first_name,
        value: student.id,
      };
    });
  },
};
const mutations = {
  SET_USER (state, data) {
    state.user = data;
  },
  SET_USER_LOGIN (state, { token, user }) {
    state.token = token;
    state.user = user;
  },
  UPDATE_STUDENT (state, students) {
    state.user.students = students;
  },
  UNSET_USER (state) {
    state.token = '';
    state.user = {};
    state.student = {};
    state.group = {};
  },
  SET_CURRENT_STUDENT (state, info) {
    state.student = {
      id: info.studentId,
      name: info.studentName,
    };
  },
  SET_INFO_CUSTOMER (state, info) {
    state.infoCustomer = info;
  },
  SET_INFO_STUDENT (state, info) {
    state.infoStudent = info;
  },
  SET_GROUP (state, info) {
    state.group = info;
  },
  SET_PASSWORD (state, info) {
    state.allPassword = info;
  },
  SET_VERIFICATION_FORM (state, form) {
    state.verificationForm = form;
  },
  SET_USER_INFO (state, userInfo) {
    state.user = userInfo;
  },
};
const actions = {
  async loginUser ({ commit }, payload) {
    const resLogin = await http.post(apiEndpoints.login, payload);
    const { status, data, code } = resLogin;
    if (status) {
      const { token, user, status: loginStatus } = data.result;
      if (loginStatus === false) {
        return false;
      }
      commit('SET_USER_LOGIN', {
        token,
        user,
      });
      const studentName = user.students[0].last_name + ' ' + user.students[0].first_name;
      commit('SET_CURRENT_STUDENT', { studentId: user.students[0].id, studentName: studentName });
      return true;
    } else if (status === false && code === 200) {
      return false;
    } else {
      return { error_other: resLogin };
    }
  },
  clearInfoUserLoginAndRedirectToLogin ({ commit }) {
    commit('UNSET_USER');
    router.push({ name: 'login' });
  },
  unsetUser ({ commit }) {
    commit('UNSET_USER');
  },
  async logoutUser ({ commit }, payload) {
    const res = await http.post(apiEndpoints.logout);
    const { status } = res;
    if (status) {
      await commit('UNSET_USER');
      return true;
    } else {
      return false;
    }
  },
  setInfoStudent ({ commit }, data) {
    commit('SET_CURRENT_STUDENT', data);
  },
  saveInfoCustomer ({ commit }, payload) {
    commit('SET_INFO_CUSTOMER', payload);
  },
  saveInfoStudent ({ commit }, payload) {
    commit('SET_INFO_STUDENT', payload);
  },
  saveUserInfo ({ commit }, payload) {
    commit('SET_USER_INFO', payload);
  },
  saveGroup ({ commit }, payload) {
    commit('SET_GROUP', payload);
  },
  savePassword ({ commit }, payload) {
    commit('SET_PASSWORD', payload);
  },
  saveVerificationForm ({ commit }, payload) {
    commit('SET_VERIFICATION_FORM', payload);
  },
  async getInfoPostalCode (_, payload) {
    const res = await http.get(apiEndpoints.getPostalCode.replace('{postal-code}', payload.postal_code));
    const { status, data } = res;
    if (status) {
      return data.result;
    }
  },
  async registerBasic (_, payload) {
    const res = await http.post(apiEndpoints.registerBasic, payload);
    const { status, code } = res;
    if (status) {
      return { status: true, errors: {} };
    } else if (code === 422) {
      return { status: false, errors: res.data.errors };
    } else {
      return { status: false };
    }
  },
  async checkRouterRegisterCustomerEffect (_, payload) {
    const res = await http.post(apiEndpoints.registerInfoCustomer + payload.verified_code, payload);
    const { status, code } = res;
    if (status && code === 200) {
      return true;
    } else {
      return false;
    }
  },
  async resetPasswordSendMail (_, payload) {
    const res = await http.post(apiEndpoints.resetPasswordSendMail, payload);
    const { status, code, data } = res;
    if (status && code === 200) {
      return { status: true, errors: {} };
    } else if (code === 422) {
      return { status: false, errors: data.errors };
    }
  },
  async settingPassword (_, payload) {
    const res = await http.post(apiEndpoints.settingPassword + payload.verify_code, payload);
    const { status, code } = res;
    if (status && code === 200) {
      return true;
    } else {
      return false;
    }
  },
  async registerCustomer (_, payload) {
    const res = await http.post(apiEndpoints.registerInfoCustomer + payload.verified_code, payload);
    const { status, code } = res;
    if (status && code === 200) {
      return { status: true };
    } else if (!status && code === 422) {
      return { status: false, error: 'error validate' };
    } else {
      return { status: false };
    }
  },
  async checkRouterSettingPasswordEffect (_, payload) {
    const res = await http.post(apiEndpoints.settingPassword + payload.verify_code, payload);
    const { status, code } = res;
    if (status && code === 200) {
      return true;
    } else {
      return false;
    }
  },
  async updateProfile (_, payload) {
    return await http.put(apiEndpoints.updateProfile, payload);
  },
  async getProfileInfo (_) {
    return await http.get(apiEndpoints.getProfileInfo);
  },
  async getGroupByCode (_, payload) {
    return await http.get(apiEndpoints.getGroupByCode, payload);
  },
  async addGroupForStudent (_, payload) {
    return await http.post(apiEndpoints.addGroupForStudent, payload);
  },
  async registerStudent (_, payload) {
    return await http.post(apiEndpoints.registerStudent, payload);
  },
  async checkDuplicateName (_, payload) {
    return await http.post(apiEndpoints.checkDuplicateName, payload);
  },
  async getStudentForCustomerId ({ commit }) {
    const res = await http.get(apiEndpoints.getStudentForCustomerId);
    const { status, data } = res;
    if (status) {
      commit('UPDATE_STUDENT', data.result);
      (data.result).forEach(student => {
        if (student.id === this.state.userStore.student.id) {
          const studentName = student.last_name + ' ' + student.first_name;
          commit('SET_CURRENT_STUDENT', { studentId: student.id, studentName: studentName });
        }
      });
    }
  },
};
export default {
  state,
  mutations,
  actions,
  getters,
};
