export default {
  login: {
    title: 'ログイン',
    button: {
      login: 'ログイン',
      start: '初めての方',
    },
    label: {
      login_id: 'ログインID（メールアドレス）',
      email: 'メールアドレス',
      password: 'パスワード',
      forgot_password: 'パスワードをお忘れの方',
    },
    messages: {
      fail: 'ログインID、パスワードをご確認ください。',
      ErrorNetwork: '接続エラー',
    },
  },
};
