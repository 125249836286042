export default {
  titles: {
    terms_of_use: 'ご利用規約',
    privacy_policy: 'プライバシーポリシー',
    return_policy: '返品ポリシー',
    commercial_transaction_law: '特定商取引法に基づく表記',
    how_to_cancel_spam: '迷惑メール設定解除方法',
    external_characters: '外字を含む方はこちら',
  },
};
