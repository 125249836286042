<template>
  <v-menu
    ref="menuDate"
    v-model="menuDate"
    :return-value="date"
    :close-on-content-click="false"
    transition="scale-transition"
    offset-y
    content-class="v-menu--calendar elevation-1"
    :disabled="isDisable"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        :value="formatDate(date)"
        autocomplete="off"
        dense
        v-on="on"
        v-bind="attrs"
        :outlined="isOutlined"
        hide-details
        :placeholder="datePlaceHolder"
        readonly
        clearable
        :label="label"
        class="v-input--datepicker"
        :class="classList"
        :disabled="isDisable"
        @click:clear="doClear"
      >
        <template slot="append">
          <svg-icon icon="calendar"></svg-icon>
        </template>
      </v-text-field>
    </template>
    <v-date-picker
      v-model="date"
      no-title
      locale="ja-jp"
      color="#00bcb5"
      :type="datePickerType"
      :min="min"
      :max="max"
      :reactive="true"
      :day-format="(date) => new Date(date).getDate()"
      @change="save"
      :active-picker.sync="activePicker"
      :disabled="isDisable"
      :show-current="showCurrent"
    ></v-date-picker>
  </v-menu>
</template>

<script>
import moment from 'moment';
const DEFAULT_DATE_FORMAT = 'YYYY/MM/DD';
const DEFAULT_MONTH_FORMAT = 'YYYY/MM';
const DATE_FORMAT = 'YYYY-MM-DD';

export default {
  name: 'DatePicker',
  props: {
    label: { type: String, default: null },
    min: { type: String, default: null },
    max: { type: String, default: moment(new Date()).format(DATE_FORMAT) },
    isDisable: { type: Boolean, default: false },
    classList: { type: [String, Object], default: '' },
    isOutlined: { type: Boolean, default: true },
    valueInit: { type: String, default: null },
    type: { type: String, default: 'date' },
    showCurrent: { type: Boolean, default: false },
  },
  data () {
    return {
      menuDate: '',
      date: null,
      activePicker: null,
    };
  },
  created () {
    if (this.valueInit) {
      this.date = this.valueInit;
    }
  },
  methods: {
    onInputDate () {
      this.$emit('input', this.date);
      this.$emit('setClearDate');
    },
    doClear () {
      this.date = null;
      this.$emit('input', this.date);
      this.$emit('setClearDate');
    },
    formatDate (value) {
      if (value) {
        return moment(value).format(this.pickerDateFormat);
      }
      return null;
    },
    save (date) {
      this.$refs.menuDate.save(date);
      this.$emit('input', this.date);
      this.$emit('setClearDate');
    },
  },
  computed: {
    datePickerType () {
      return this.type === this.$constant.type_date ? this.$constant.type_date : this.$constant.type_month;
    },
    pickerDateFormat () {
      return this.type === this.$constant.type_date ? DEFAULT_DATE_FORMAT : DEFAULT_MONTH_FORMAT;
    },
    datePlaceHolder () {
      return this.type === this.$constant.type_date ? '----/--/--' : '----/--';
    },
  },
  watch: {
    valueInit (newVal) {
      // this.date = this.$commonHelper.formatDate(newVal);
      this.date = newVal;
    },
    menuDate (newVal) {
      newVal && setTimeout(() => (this.activePicker = 'YEAR'));
    },
  },
  filters: {
    formatDate (value) {
      if (value) {
        return moment(value).format(DEFAULT_DATE_FORMAT);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.v-input.v-input--dense >>> .v-input__append-inner {
  margin-top: 7px !important;
}
.v-menu--calendar {
  min-width: auto !important;
}
.v-input--datepicker >>> .v-input__icon--clear {
  opacity: 1 !important;
}
</style>
<style lang="scss">
.v-date-picker-years {
  scroll-behavior: auto;
  li {
    font-size: 16px;
  }
}
</style>
