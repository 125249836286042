import http from '@/services/http';
import apiEndpoints from '@/services/apiEndpoints';

const actions = {
  async getInfoGroupById (_, payload) {
    const res = await http.get(apiEndpoints.getGroupInfoById.replace('{group_id}', payload.group_id));
    const { status, data } = res;
    if (status) {
      return data.result;
    }
  },
};
export default {
  actions,
};
